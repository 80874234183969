@charset 'UTF-8';
@use './global/' as *;



.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include baseContainer(1rem);
}



.footer-brand {
  font-weight: 700;
  a {
    color: $gray-dark;
    @include hover(){
      color: $gray-600;
    }
  }
}



.footer-component {
  display: block;
}



.footer-legal {
  background-color: $gray-100;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}



.footer-legal-copyright {
  color: $gray-600;
  font-size: .75rem;
}
