@charset 'UTF-8';
@use './global/' as *;



.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include baseContainer(1rem);
}



.header-brand {
  font-weight: 700;
  a {
    color: $gray-dark;
    @include hover(){
      color: $gray-600;
    }
  }
}



.header-component {
  display: block;
}
