@charset 'UTF-8';
@use './global/' as *;



.burger-icon {
  background-color: $white;
  background-image: set-svg($burger, $base);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  box-shadow: $base-box-shadow;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: background-color $base-transition, background-image $base-transition;
  width: 40px;
  height: 40px;

  @include hover(){
    background-color: $gray-100;
  }

  #{$collapse-class-name} & {
    background-color: $accent;
    background-image: set-svg($burger, $white);
  }
}
