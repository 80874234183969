@charset 'UTF-8';
@use './global/' as *;
@use 'sass:math';



// margin and padding
.m0  { margin         : 0 !important; }
.mt0 { margin-top     : 0 !important; }
.mb0 { margin-bottom  : 0 !important; }
.ml0 { margin-left    : 0 !important; }
.mr0 { margin-right   : 0 !important; }
.p0  { padding        : 0 !important; }
.pt0 { padding-top    : 0 !important; }
.pb0 { padding-bottom : 0 !important; }
.pl0 { padding-left   : 0 !important; }
.pr0 { padding-right  : 0 !important; }

$margin-padding-properties: (
  mt: margin-top,
  mb: margin-bottom,
  mr: margin-right,
  ml: margin-left,
  pt: padding-top,
  pb: padding-bottom,
  pr: padding-right,
  pl: padding-left
) !default;

@each $media, $property in $margin-padding-properties {
  @for $i from 1 through 25 {
    .#{$media}#{$i * 4} {
      #{$property}: ( $i * 4 ) + px !important;
      @each $key, $value in $scales {
        @include media($key){
          #{$property}: ceil( $i * 4 * $value ) + px !important;
        }
      }
    }
  }
}



// font color
@each $name, $color in $all-colors {
  .fc-#{$name} {
    color: $color !important;
  }
}



// font size
@each $name, $size in $font-size-percentage {
  .fs-#{$name} {
    font-size: $size !important;
  }
}



// line height
@for $i from 10 through 20 {
  .lh-#{$i} {
    line-height: #{$i * 0.1} !important;
  }
}



// background color
@each $name, $color in $all-colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}



// width
@for $i from 1 through 20 {
  .w#{$i * 5} {
    width: #{$i * 5%} !important;
  }
}



// text-align
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align-last: justify;
  text-justify: inter-ideograph;
}



// spacer
.spacer {
  margin-top: $base-spacer;
}

.spacer-large {
  margin-top: $base-spacer * 2 !important;
}

.spacer-xlarge {
  margin-top: $base-spacer * 3 !important;
}

.spacer-exlarge {
  margin-top: $base-spacer * 4 !important;
}

.spacer-small {
  margin-top: math.div($base-spacer, 2);
}

.spacer-top-bottom {
  margin: $base-spacer 0;
}

.spacer-top-bottom-large {
  margin: ($base-spacer * 2) 0;
}

.spacer-top-bottom-xlarge {
  margin: ($base-spacer * 3) 0;
}

.spacer-top-bottom-exlarge {
  margin: ($base-spacer * 4) 0;
}



// clearfix
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}
