@charset 'UTF-8';
@use './global/' as *;



.frame-border {
  background-color: $white;
  border: 1px solid $gray-dark;
  padding: 1rem;
}



.frame-mat {
  background-color: $gray-100;
  padding: 1rem;
}
