@charset 'UTF-8';
@use './global/' as sys;



.vacancy-wrapper {
  position: relative;
  // 更新日
  .update-date {
    font-size: .875em;
    font-weight: 500;
    margin-bottom: .25em;
    text-align: right;
  }
}



// 注釈
ul.vacancy-notes-list {
  font-size: .875em;
  margin-top: .325em;
  li {
    span {
      display: inline-block;
      &.male::before, &.female::before {
        content: '■';
      }
      &.male {
        &::before {
          color: sys.$male-color;
        }
      }
      &.female {
        margin-left: 1em;
        &::before {
          color: sys.$female-color;
        }
      }
    }
  }
}



// コメント
.vacancy-comment-wrapper {
  border: 1px solid sys.$table-border-color;
  font-size: .875em;
  margin-top: 1em;
  padding: 1em;
}



// 短期入所サービス（ショートステイ）の空室状況 見出し
.vacancy-heading {
  border-bottom: 1px solid sys.$gray-200;
  font-size: 1.25em;
  margin-bottom: .5em;
  // アイコン
  &::before {
    content: '';
    background-image: sys.set-svg(sys.$calendar, sys.$base);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: .25em;
    position: relative;
    top: 3px;
    width: 1.25em;
    height: 1.25em;
  }
  // 日付
  .date {
    display: inline-flex;
    font-size: 1.25em;
  }
}





/** TABLE **/



// 入所サービス（ロングステイ）の空室状況
table.entrance-table {
  th, td {
    border: 1px solid sys.$table-border-color;
  }
  th {
    background-color: sys.$table-th-background-color;
    font-size: .875em;
    font-weight: 500;
    padding: .3em .5em;
    text-align: center;
    white-space: nowrap;
  }
  td {
    background-color: sys.$table-td-background-color;
    padding: 1em;
    vertical-align: middle;
  }
}



// ○ △ ✕
table.vacancy-table {
  table-layout: fixed;
  &.daycare-comb {
    // 通所リハビリの空き状況（短時間含む）
    table-layout: auto;
  }
  th, td {
    border: 1px solid sys.$table-border-color;
  }
  th {
    background-color: sys.$table-th-background-color;
    font-size: .875em;
    font-weight: 500;
    padding: .25em 1em;
    text-align: center;
    vertical-align: middle;
  }
  td {
    background-color: sys.$table-td-background-color;
    overflow-wrap : break-word;
    padding: .5em;
    // ○ △ ✕
    .room-abled,
    .room-disabled,
    .room-discuss,
    .room-transparent {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin: 0 auto;
      height: 1.5em;
      width: 1.5em;
    }
    .room-abled {
      background-image: sys.set-svg(sys.$circle, sys.$gray-dark);
    }
    .room-discuss {
      background-image: sys.set-svg(sys.$triangle, sys.$gray-dark);
    }
    .room-disabled {
      background-image: sys.set-svg(sys.$dismiss, sys.$gray-dark);
    }
  }
}



// 短期入所サービス（ショートステイ）の空室状況
table.vacancy-short-table {
  table-layout: fixed;
  width: 100%;
  th, td {
    border: 1px solid sys.$table-border-color;
  }
  th {
    background: sys.$table-th-background-color;
    font-size: .875em;
    font-weight: 500;
    padding: .25em .5em;
    text-align: center;
    vertical-align: middle;
  }
  td {
    background: sys.$table-td-background-color;
    overflow-wrap : break-word;
    padding: .35em 0;
    vertical-align: top;
    // 日付
    .day {
      font-size: .75em;
      text-align: center;
    }
    // ○ △ ✕
    .result {
      display: flex;
      justify-content: center;
    }

    .room-abled,
    .room-disabled,
    .room-discuss,
    .room-transparent {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      //margin: 0 auto;
      height: 1em;
      width: 1em;
    }
    .male {
      &.room-abled {
        background-image: sys.set-svg(sys.$circle, sys.$male-color);
      }
      &.room-discuss {
        background-image: sys.set-svg(sys.$triangle, sys.$male-color);
      }
      &.room-disabled {
        background-image: sys.set-svg(sys.$dismiss, sys.$male-color);
      }
    }
    .female {
      &.room-abled {
        background-image: sys.set-svg(sys.$circle, sys.$female-color);
      }
      &.room-discuss {
        background-image: sys.set-svg(sys.$triangle, sys.$female-color);
      }
      &.room-disabled {
        background-image: sys.set-svg(sys.$dismiss, sys.$female-color);
      }
    }
  }
}



// 訪問リハビリの空き状況（時間別）
table.visit-time-table {
  table-layout: fixed;
  th, td {
    border: 1px solid sys.$table-border-color;
  }
  th {
    background-color: sys.$table-th-background-color;
    font-size: .875em;
    font-weight: 500;
    padding: .25em;
    text-align: center;
    vertical-align: middle;
  }
  td {
    background: sys.$table-td-background-color;
    overflow-wrap : break-word;
    padding: 1em;
    position: relative;
  }
}

// Baloon
.baloon-trigger {
  cursor: pointer;
  display: block;
  position: relative;
  &.js-room-disabled {
    cursor: auto;
  }
  // ○ △ ✕
  .room-abled,
  .room-disabled,
  .room-discuss {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin: 0 auto;
    height: 1.5em;
    width: 1.5em;
  }
  .room-abled {
    background-image: sys.set-svg(sys.$circle, sys.$gray-dark);
  }
  .room-discuss {
    background-image: sys.set-svg(sys.$triangle, sys.$gray-dark);
  }
  .room-disabled {
    background-image: sys.set-svg(sys.$dismiss, sys.$gray-dark);
  }
}

ul.baloon {
  background-color: sys.$base;
  border-radius: 8px;
  box-shadow: sys.$base-box-shadow-small;
  padding: .25rem 1rem;
  position: absolute;
  top: 84%;
  width: max-content;
  visibility: hidden;
  opacity: 0;
  transition: visibility sys.$base-transition, opacity sys.$base-transition;
  z-index: 1;
  .baloon-active & {
    visibility: visible;
    opacity: 1;
  }

  &.thu, &.fri, &.sat {
    right: 0;
  }

  li {
    color: sys.$white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .75em;
    line-height: 3;
    &:nth-of-type(n+2){
      border-top: 1px solid sys.$gray-200;
    }
    .str {
      display: inline-block;
    }
  }
  // ○ △ ✕
  .room-abled,
  .room-disabled,
  .room-discuss {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    margin-left: 1em;
    height: 1.25em;
    width: 1.25em;
  }
  .room-abled {
    background-image: sys.set-svg(sys.$circle, sys.$white);
  }
  .room-discuss {
    background-image: sys.set-svg(sys.$triangle, sys.$white);
  }
  .room-disabled {
    background-image: sys.set-svg(sys.$dismiss, sys.$white);
  }
}
