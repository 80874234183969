@charset 'UTF-8';
@use './global/' as sys;



.info-news-list {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  margin: 0;
  padding: 0;
  width: 100%;
}



.news-container {
  padding: 1rem 0;
  @include sys.media(lg) {
    display: table-row;
    padding: 0;
  }
  & + & {
    border-top: solid 1px sys.$base-border-color;
  }
  &:last-child {
    margin-bottom: 0;
  }
  .news-body {
    padding: .5rem 0;
    font-family: hind;
    @include sys.media(lg) {
      padding: 1.25rem 0;
    }
  }
  .date {
    color: #707070;
    display: table-cell;
    font-family: hind;
    padding: .5rem;
    white-space: nowrap;
    @include sys.media(lg) {
      padding: 1.25rem 1.25rem 1.25rem 0;
    }
  }
  .category {
    display: table-cell;
    padding: .5em;
    @include sys.media(lg) {
      padding: 1.25rem;
    }
    .category-label {
      &.patient {
        background-color: sys.$uno;
        color: sys.$white;
      }
      &.ordinary {
        background-color: sys.$dos;
        color: sys.$white;
      }
      &.participants {
        background-color: sys.$accent;
        color: sys.$white;
      }
    }
  }
  .title {
    color: #707070;
    display: block;
    padding:  0 .5rem .5rem .5rem;
    width: 100%;
    overflow-wrap: anywhere;
    @include sys.media(lg) {
      display: table-cell;
      padding: 1.25rem 0 1.25rem 1.25rem;
    }
    a {
      color: #707070;
      transition: color sys.$base-transition;
      &:hover {
        color: sys.$gray-500;
      }
    }
    a[target="_blank"]:not(a[href$=".pdf"]){
      display: inline-flex;
      align-items: center;
      position: relative;
      &::after {
        content: '';
        background-image: sys.set-svg(sys.$ext, sys.$uno);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.125rem;
        height: 1.125rem;
        min-width: 1.25rem;
        position: relative;
        margin-left: .5rem;
        transition: opacity sys.$base-transition;
      }
    }
    a[href$=".pdf"] {
      display: inline-flex;
      align-items: center;
      position: relative;
      &::after {
        content: '';
        background-image: sys.set-svg(sys.$pdf, sys.$red);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 1.375rem;
        height: 1.375rem;
        min-width: 1.375rem;
        position: relative;
        margin-left: .5rem;
        transition: opacity sys.$base-transition;
      }
    }
  }
  .category-label {
    background: #ccc;
    padding: .25rem .5rem;
    font-size: .75rem;
    display: block;
    text-align: center;
    white-space: nowrap;
    border-radius: .25rem;
  }
}



.news-post-date {
  display: flex;
  align-items: center;
  position: relative;
  color: sys.$gray-600;
  font-weight: 700;
  font-size: .875em;
  margin-bottom: 2em;
  &::before {
    content: '';
    background-image: sys.set-svg(sys.$calendar, sys.$gray-600);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    position: relative;
    margin-right: .5rem;
    transition: opacity sys.$base-transition;
  }
}
