@charset 'UTF-8';



// display: none; からのフェードイン
//
@keyframes show {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes transY {
  0% {
      margin-top: -16px;
  }
  100% {
      margin-top: 0;
  }
}



@keyframes rotate-loading {
  0%  {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



@keyframes loading-text-opacity {
  0%  {
    opacity: 1
  }
  20% {
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100%{
    opacity: 1
  }
}
