@charset 'UTF-8';
@use './global/' as *;



.table {
  thead
  {
    th {
      background-color: $table-th-background-color;
      border: 1px solid $table-border-color;
      color: $gray-dark;
      padding: .6em 1em;
      text-align: center;
    }
  }

  tbody {
    th, td {
      border: 1px solid $table-border-color;
    }
    th {
      background-color: $table-th-background-color;
      padding: .6em 1em;
    }
    td {
      background-color: $table-td-background-color;
      overflow-wrap : break-word;
      padding: .6em 1em;
    }
  }

  // wide
  &.table-wide {
    width: 100%;
  }

  // fixed
  &.table-fixed {
    table-layout: fixed;
  }
}



.table-block, .th-block {
  border-bottom: 1px solid $table-border-color;
  width: 100%;
  @include media(lg) {
    border: none;
    width: auto;
  }
  tbody {
    th, td {
      display: block;
      @include media(lg) {
        display: table-cell;
      }
    }
    th {
      border-bottom: none;
      width: 100%;
      @include media(lg) {
        border-bottom: 1px solid $table-border-color;
        width: auto;
      }
    }
    td {
      border-bottom: none;
      @include media(lg) {
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
}



// スクロールを出すための wrapper
.table-scroll-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  @include media(md) {
    white-space: normal;
  }
}
