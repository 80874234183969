@charset 'UTF-8';
@use './global/' as *;



.construct-container {
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}



.construct-header {
  background-color: $header-background-color;
  flex: 0 0 auto;
  width: 100%;
  z-index: $z-index-header;
}



.construct-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
}



.construct-footer {
  background-color: $footer-background-color;
  flex: none;
  width: 100%;
  z-index: $z-index-footer;
}



.container {
  @include baseContainer();
}



.section-content, .section-block {
  width: 100%;
  & + & {
    margin-top: $section-content-gutter;
  }
}



.center-block {
  display: block;
  margin: 0 auto;
}
