@charset 'UTF-8';
@use './global/' as *;



.icon {
  display: inline-flex;
  align-items: center;
  position: relative;

  &::before, &::after {
    @include setSvgIcon();
    position: relative;
    //top: .1em;
    margin: 0 .25em;
  }

  // デフォルトでは右側にアイコン
  &::after {
    content: '';
  }
  &::before {
    content: none;
  }
  // 左側に表示
  &.left {
    &::before {
      content: '';
    }
    &::after {
      content: none;
    }
  }

  // 種類とカラー
  @each $name, $icon in $svg-icon-types {
    &.#{$name} {
      &::before, &::after {
        background-image: set-svg($icon, inherit);
      }
      @each $color-name, $color in $all-colors {
        &.ic-#{$color-name} {
          &::before, &::after {
            background-image: set-svg($icon, $color);
          }
        }
      }
    }
  }
}
