@charset 'UTF-8';
@use '../global/' as *;



.form-grid {
  display: flex;
  flex-flow: row wrap;
  margin-top: calc( var(--form-grid-gutter) * -.5 );
  margin-right: calc( var(--form-grid-gutter) * -.5 );
  margin-left: calc( var(--form-grid-gutter) * -.5 );
  margin-bottom: calc( var(--form-grid-gutter) * -.5 );

  // 隣接
  & + & {
    margin-top: calc( var(--form-grid-gutter) * .5 );
  }

  // col
  .col {
    padding: calc( var(--form-grid-gutter) * .5 );
  }

  @each $key, $value in $breakpoints {
    @include media($key){
      @for $i from 1 through $form-grid-columns {
        @include makeGridColumns( $key, $i );
      }
    }
  }
}
