@charset 'UTF-8';
@use './global/' as sys;



.schedule-list-wrapper {
  position: relative;
}



.schedule-wrapper {
  position: relative;
  & + & {
    margin-top: 4rem;
  }
}



.schedule-section-name {
  border-top: 1px solid sys.$gray-300;
  border-bottom: 1px solid sys.$gray-300;
  font-weight: 700;
  margin-bottom: 1em;
  padding: .5em 1em;
}



.section-schedule-wrapper {
  position: relative;
}



table.section-schedule-table {
  text-align: center;
  width: 100%;

  thead {
    th, td {
      background-color: sys.$table-thead-th-background;
      border: 1px solid sys.$table-border-color;
      color: sys.$white;
      padding: .5em;
      font-size: .9375em;
    }
  }

  tbody {
    th, td {
      border: 1px solid sys.$table-border-color;
      &:nth-of-type(n+2) {
        @include sys.media(md) {
          width: 15%;
        }
      }
    }

    th {
      background-color: sys.$table-thead-th-background;
      color: sys.$white;
      font-weight: normal;
      padding: 1em;
      vertical-align: middle;
    }

    td {
      background-color: sys.$table-td-background-color;
      overflow-wrap : break-word;
      padding: 1em;
    }
  }

  .time-zone_1,
  .time-zone_2,
  .time-zone_3 {
    background-color: sys.$table-tbody-th-background;
    font-weight: 700;
    font-size: .9375em;
    white-space: nowrap;
  }

  .reserve {
    background-color: sys.$gray-300;
    color: sys.$gray-800;
    font-size: .75em;
    font-weight: 700;
    display: inline-block;
    line-height: 1.3;
    padding: .375em .5em;
    white-space: nowrap;
    &.type_1 {
      display: none;
    }
    &.type_2 {
      background-color: sys.$uno;
      border: 1px solid sys.$uno;
      color: sys.$white;
    }
    &.type_3 {
      background-color: sys.$white;
      border: 1px solid sys.$uno;
      color: sys.$uno;
    }
  }

  .doctor-container {
    & + .doctor-container {
      margin-top: 1em;
    }
  }

  .doctor-name {
    font-weight: 700;
    margin-top: .25em;
    white-space: nowrap;
    // 男性医師名のカラー
    &.doctor-name-male {
      color: sys.$gray-dark;
    }
    // 女性医師名のカラー
    &.doctor-name-female {
      color: sys.$pink;
    }
  }

  .comment,
  .comment-sub {
    font-size: .75em;
    line-height: 1.3;
    margin-top: .25em;
  }

  // 縦組み
  &.vertical {
    thead {
      th {
        width: 14%;
      }
      td {
        width: 43%;
      }
    }
  }

}



.update-at-schedule {
  color: sys.$gray-600;
  font-size: .875em;
  font-weight: 700;
  margin-bottom: .5em;
  text-align: left;
}



.schedule-lower-comment {
  margin-top: 1em;
}

.lower-comment{
  font-size: .875em;
  line-height: 1.5;
  & + & {
    margin-top: .5em;
  }
}
