@charset 'UTF-8';
@use './global/' as sys;



.preview-header {
  margin: 0 0 4rem;
  padding: 1rem 0;
}

.preview-header-container {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  .header-headline {
    font-size: 1.5rem;
    font-weight: 500;
  }
}



.preview-block {
  position: relative;
  & + & {
    margin: 16rem 0 0;
  }
  &:last-child {
    margin-bottom: 4rem;
  }
}



.preview-headline {
  border-bottom: 1px solid sys.$gray-300;
  color: sys.$gray-700;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1em;
}



// index.php
.index-wrapper {
  background-color: sys.$gray-100;
  padding: 4rem 0 0;
  width: 100%;
  height: 100%;
}

.index-container {
  container-type: inline-size;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 960px;
}

.index-column {
  flex: 0 0 50%;
  padding: 1rem;
  @container ( max-width: 575px ){
    flex: 0 0 100%;
  }
}

$btn-svg-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve" fill=""><polygon points="12.9,4 10.7,6.1 15.1,10.4 3,10.4 3,13.6 15,13.6 10.7,17.9 12.9,20.1 21,12 "/></svg>') !default;


.index-card {
  background-color: sys.$white;
  box-shadow: sys.$base-box-shadow;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  padding: 2rem 1rem 1rem;
  height: 100%;

  h1 {
    font-size: 1.5rem;
    margin-bottom: 1.5em;
  }

  table {
    margin-bottom: 3rem;
    th, td {
      border: 1px solid sys.$table-border-color;
      text-align: center;
    }
    th {
      background-color: #FF8CBA;
      font-size: 12px;
      font-weight: 500;
      padding: .5em 1em;
    }
    td {
      background-color: sys.$white;
      font-size: 16px;
      font-weight: 700;
      overflow-wrap : break-word;
      padding: .75em 1em;
    }
  }

  p {
    margin-bottom: 3rem;
  }

  a {
    background-color: #ff0066;
    border-radius: 8px;
    color: sys.$white;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    padding: .75em 1em;
    transition: background-color sys.$base-transition;
    width: 160px;
    &::after {
      content: '';
      background-image: sys.set-svg($btn-svg-icon, sys.$white);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1.25em;
      height: 1.25em;
    }
    @include sys.hover(){
      background-color: #C6004F;
    }
  }
}



// Functions

.function-container {
  position: relative;

  & + & {
    margin-top: 8rem;
  }

  p {
    font-size: .875em;
    margin: 0 0 2rem;
  }
}

.function-headline {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0 0 .5em;
}

pre.description {
  background-color: #1c2637;
  border-radius: 8px;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    background-color: transparent;
    color: sys.$white;
    font-family: 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', monospace;
    font-size: .875em;
    font-weight: 500;
    vertical-align: initial;
  }
}

pre.view-results {
  background-color: sys.$gray-100;
  border: 1px solid sys.$gray-300;
  margin: 2rem 0 0;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
  code {
    font-family: 'SFMono-Regular', 'Consolas', 'Liberation Mono', 'Menlo', monospace;
    font-size: .875em;
    font-weight: 500;
  }
}
