@charset 'UTF-8';
@use './global/' as *;



ul.list,
ol.list {

  li {
    &:nth-of-type(n+2){
      margin-top: .8em;
    }
  }

  ul, ol {
    margin-left: 1em;
  }

  &.li-mb {
    li:nth-of-type(n+2) {
      margin-top: .8em;
    }
    ul, ol {
      margin-top: .8em;
    }
  }

  // インデント
  &.indent {
    margin-left: 1em;
    ul, ol {
      margin-top: .8em;
    }
  }
}



ul.list {
  // dotted
  &.disc {
    list-style: disc outside;
    padding-left: 1.125em;
    ul {
      list-style: circle outside;
    }
  }

  // 四角 & 丸
  &.square,
  &.circle {
    li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '';
        color: inherit;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  &.square {
    li {
      &:before {
        content: '■';
      }
    }
  }

  &.circle {
    li {
      &:before {
        content: '●';
      }
    }
  }

  // 注釈
  &.notes {
    li {
      line-height: inherit;
      padding-left: 1.25em;
      position: relative;
      &:before {
        content: '※';
        color: inherit;
        display: inline-block;
        font-family: sans-serif;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
