@charset 'UTF-8';
@use './global/' as *;



// variable
$pagination-active-class: '.current';



.pagination-wrapper {
  width: auto;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: 1rem;
  text-align: center;

  li {
    display: block;
    &:nth-of-type(n+2){
      margin-left: .5rem;
    }
  }

  .prev,
  .next,
  .page,
  #{$pagination-active-class} {
    border-radius: 3px;
    border: 2px solid $gray-200;
    display: block;
    line-height: 1;
    padding: 1rem;
    transition: background-color $base-transition;
  }

  .prev,
  .next {
    color: $gray-dark;
  }

  .page {
    background-color: $white;
    color: $gray-dark;
  }

  #{$pagination-active-class} {
    background-color: $gray-200;
    font-weight: 700;
  }

  .disabled {
    background-color: $gray-200;
    color: $gray-600;
  }

  .prev,
  .next,
  .page {
    &:not(.disabled){
      @media(hover: hover){
        &:hover {
          background-color: $gray-100;
        }
      }
    }
  }
}
