@charset 'UTF-8';
@use 'sass:math';
@use './global/' as sys;



.recruit-list {
  width: 100%;
  li {
    background-color: transparent;
    &:nth-of-type(n+2){
      margin-top: 4em;
    }
  }
}



.jobs-heading {
  background-color: sys.$white;
  border-top: 1px solid sys.$gray-600;
  border-bottom: 1px solid sys.$gray-600;
  color: sys.$gray-dark;
  font-weight: 700;
  margin-bottom: 1em;
  padding: .6em 0;
  text-align: center;
  @include sys.media(lg){
    margin-bottom: 1em;
  }
}



.recruit-btn-container {
  display: block;
  margin: -.5rem;
  @include sys.media(md){
    display: flex;
    flex-flow: row wrap;
  }
  & + & {
    margin-top: .5rem;
  }

  .btn-column {
    padding: .5rem;
    @include sys.media(md){
      flex: 0 0 percentage(math.div(1, 3));
    }
  }
}



.btn-recruit {
  background-color: transparent;
  border: 2px solid transparent;
  color: transparent;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: .0625em;
  line-height: 1;
  padding: calc(.625em + .5rem) 1em;
  position: relative;
  text-align: center;
  transition: background-color sys.$base-transition, color sys.$base-transition;
  width: 100%;
  height: 100%;
  &::after {
    content: '';
    background-image: sys.set-svg(sys.$arrow, sys.$white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 1em;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
  }

  // 募集していません
  &.disabled {
    background-color: #D9E1EE !important;
    border-color: sys.$gray-200 !important;
    color: sys.$gray-dark !important;
    //display: block;
    padding: calc(.625em + .5rem) 1em;
    pointer-events: none;
    &::after {
      content: none;
    }
    span {
      @include sys.media(md){
        display: block;
      }
      @include sys.media(lg){
        display: inline;
      }
    }
  }

  // 新卒
  &.shin {
    background-color: sys.$recruit-shin-color;
    border-color: sys.$recruit-shin-color;
    color: sys.$white;
    @media(hover: hover){
      &:hover {
        background-color: sys.$white;
        color: sys.$recruit-shin-color;
        &::after {
          background-image: sys.set-svg(sys.$arrow, sys.$recruit-shin-color);
        }
      }
    }
  }

  // 中途
  &.chu {
    background-color: sys.$recruit-chu-color;
    border-color: sys.$recruit-chu-color;
    color: sys.$white;
    @media(hover: hover){
      &:hover {
        background-color: sys.$white;
        color: sys.$recruit-chu-color;
        &::after {
          background-image: sys.set-svg(sys.$arrow, sys.$recruit-chu-color);
        }
      }
    }
  }

  // パート
  &.part {
    background-color: sys.$recruit-part-color;
    border-color: sys.$recruit-part-color;
    color: sys.$white;
    @media(hover: hover){
      &:hover {
        background-color: sys.$white;
        color: sys.$recruit-part-color;
        &::after {
          background-image: sys.set-svg(sys.$arrow, sys.$recruit-part-color);
        }
      }
    }
  }

  // 外部サイトリンク
  &.external-site-url {
    position: relative;
    &::after {
      content: '';
      background-image: sys.set-svg(sys.$open-new-window, sys.$white);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
    }
    &.shin:hover {
      &::after {
        background-image: sys.set-svg(sys.$open-new-window, sys.$recruit-shin-color);
      }
    }
    &.chu:hover {
      &::after {
        background-image: sys.set-svg(sys.$open-new-window, sys.$recruit-chu-color);
      }
    }
    &.part:hover {
      &::after {
        background-image: sys.set-svg(sys.$open-new-window, sys.$recruit-part-color);
      }
    }
  }
}



// 募集要領

.recruit-detail-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.recruit-heading {
  background-color: #F7F6F3;
  border: 1px solid #E3E1DA;
  border-radius: 6px;
  font-size: 1rem;
  margin:  0 0 1em;
  padding: .5em 1em;
}

ul.recruit-detail-list {
  $border: 1px solid sys.$base-border-color;

  display: block;
  width: 100%;
  @include sys.media(md){
    border-collapse: collapse;
    display: table;
  }

  li {
    border: $border;
    display: block;
    &:nth-of-type(n+2){
      border-top: none;
    }
    @include sys.media(md){
      display: table-row;
    }

    .detail-head {
      background-color: sys.$gray-100;
      border-bottom: $border;
      display: block;
      padding: .5em 1em;
      text-align: center;
      @include sys.media(md){
        border-right: $border;
        display: table-cell;
        vertical-align: middle;
      }
    }

    .detail-content {
      display: block;
      padding: 1em;
      @include sys.media(md){
        display: table-cell;
      }
    }
  }
}



.form-btn-wrapper {
  margin-top: 4rem;
}

.form-btn-container {
  display: flex;
  justify-content: center;
}

.form-btn-column {
  flex: 0 0 240px;
}
