@charset 'UTF-8';
@use 'sass:math';
@use './color' as *;



// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;



$base-transition: .2s ease-out;

$base-box-shadow:       0 .5rem 1rem rgba($black, .15) !default;
$base-box-shadow-small: 0 .125rem .25rem rgba($black, .075) !default;
$base-box-shadow-large: 0 1rem 3rem rgba($black, .175) !default;

$base-border-color: #ddd !default;

// Table
$table-border-color:        $gray-300 !default;
$table-th-background-color: $gray-100 !default;
$table-td-background-color: $white    !default;
$table-thead-th-background: #587EAA !default;
$table-tbody-th-background: #DCEFEF !default;

// 休診・代診 Color
$closure-bg-color      : #F2DCD7 !default;
$closure-title-bg-color: #EF5A39 !default;
$assist-bg-color       : #ECEEF8 !default;
$assist-title-bg-color : #7B90D2 !default;

// 採用情報
$recruit-shin-color: $uno !default; // 新卒
$recruit-chu-color : $uno !default; // 中途
$recruit-part-color: $uno !default; // パート



// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29'),
) !default;
