@charset 'UTF-8';
@use './global/' as sys;



.gouhi-wrapper {
  background-color: sys.$white;
  position: relative;
}



.gouhi-headline {
  font-size: 1.5rem;

  span {
    display: block;
  }
}



.gouhi-description {
  margin: 2em 0;
}



.gouhi-container {
  margin: 0 auto;
  width: min(100%, 360px);
}



.gouhi-field {
  & + & {
    margin: 2rem 0 0;
  }

  label {
    display: block;
    font-size: .875em;
    margin: 0 0 .5em;
  }
}



.gouhi-input {
  $component-border-color: sys.$gray-400 !default;
  $component-border-width: 2px !default;
  $component-border-radius: 8px !default;
  $placeholder-color: sys.$gray-600 !default;

  background-color: sys.$gray-200;
  border: $component-border-width solid transparent;
  border-radius: $component-border-radius;
  box-shadow: none;
  color: sys.$gray-dark;
  font-size: 16px;
  line-height: 1;
  padding: .75em 1em;
  width: 100%;
  transition: border-color sys.$base-transition, background-color sys.$base-transition;

  // プレースホルダー
  &::placeholder {
    font-size: .875em;
    transition: color sys.$base-transition;
  }
  &::-moz-placeholder {
    color: $placeholder-color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $placeholder-color;
  }
  &::-webkit-input-placeholder {
    color: $placeholder-color;
  }
  &::-ms-expand {
    background-color: sys.$white;
    border: 0;
  }

  // フォーカス
  &:focus,
  &:focus:not(:placeholder-shown) {
    background-color: sys.$white;
    border-color: sys.$base;
    box-shadow: 0 0 6px 1px rgba( sys.$base, .075 );
    outline: 0;
    &::placeholder {
      color: transparent;
    }
  }
  // 入力済み
  &:not(:placeholder-shown) {
    background-color: sys.$gray-100;
    border: $component-border-width solid $component-border-color;
  }
}



.birth-grid {
  display: flex;
  gap: 4px;
}



.gouhi-btn-container {
  margin: 4em auto 0;
  text-align: center;

  button {
    $form-btn-font-size: 16px !default;
    $form-btn-height: $form-btn-font-size * 3 !default;

    background-color: sys.$base;
    border-radius: 2em;
    box-shadow: 0px 3px 1px -2px rgba( 0, 0, 0, .2 ),
                0px 2px 2px 0px rgba( 0, 0, 0, .14 ),
                0px 1px 5px 0px rgba( 0, 0, 0, .12 );
    cursor: pointer;
    color: sys.$white;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: $form-btn-font-size;
    font-weight: 700;
    line-height: $form-btn-height;
    height: $form-btn-height;
    outline: none;
    padding: 0 $form-btn-font-size * 2;
    text-align: center;
    text-decoration: none;
    transition: background-color sys.$base-transition, border-color sys.$base-transition;
    white-space: nowrap;

    &:hover {
      background-color: lighten( sys.$base, 10% );
      color: sys.$white;
    }
  }
}



.gouhi-lower {
  border: 1px solid sys.$gray-600;
  margin: 4rem 0 0;
  padding: 1.5em;

  h4 {
    font-size: 1.125em;
    font-weight: 500;
    &::before {
      content: '※';
      font-family: sans-serif;
    }
  }

  p {
    font-size: .875em;
  }
}



.gouhi-result-container {
  margin: 2em auto 0;
  width: min(100%, 360px);
}
