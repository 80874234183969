@charset 'UTF-8';
@use '../global/' as *;



label.selection-label {
  display: flex; // checkbox に高さを与えるため
  margin: 0 auto;
  //height: 100%;

  [type='radio'],
  [type='checkbox'] {

    & + .selection-label-container {
      background-color: $gray-100;
      border: 2px solid transparent;
      border-radius: 8px;
      cursor: pointer;
      color: $gray-500;
      display: flex;
      flex-direction: row;
      align-items: center;
      //height: 100%;
      padding: 1em;
      position: relative;
      transition: background-color $base-transition, border $base-transition;

      @media(hover:hover){
        &:hover {
          background-color: $gray-200;
        }
      }
      &:before {
        content: '';
        background-image: set-svg( $checkbox, $gray-500 );
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-right: .5em;
        width: 1.5em;
        height: 1.5em;
        transition: background $base-transition;
      }
    }

    &:checked + .selection-label-container {
      background-color: $gray-100;
      border: 2px solid $base;
      color: $gray-dark;
      &:before {
        background-image: set-svg( $checkbox-checked, $base );
      }
    }
  }
  // Radio
  [type='radio'] {
    & + .selection-label-container {
      &::before {
        background-image: set-svg($radio, $gray-dark);
      }
    }
    &:checked + .selection-label-container {
      &::before {
        background-image: set-svg($radio-checked, $white);
      }
    }
  }
}



.selection-checkbox-wrapper {
  display: flex;
  margin-top: 4rem;
}

.selection-checkbox-container {
  display: inline-block;
  margin: 0 auto;
  //width: 100%;
  //max-width: $form-container-max-width;
}
